import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ClassProvider, FactoryProvider, LOCALE_ID, ValueProvider } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DataService, startupServiceFactory } from '@app/services/data.service';
import { HttpSessionInterceptor } from './http-session.interceptor';
import { SetLanguageInterceptor } from './set-language.interceptor';

function addFactoryInterceptor(token: any, factory: Function, multi: boolean = false, deps: any): FactoryProvider {
    return {
        provide: token,
        useFactory: factory,
        multi,
        deps,
    };
}

function addClassInterceptor(token: any, interceptor: any, multi: boolean = false, deps: Array<any> = []): ClassProvider | FactoryProvider {
    return {
        provide: token,
        useClass: interceptor,
        multi,
        deps,
    };
}

function addValueInterceptor<T>(token: any, interceptor: T, multi: boolean = false): ValueProvider {
    return {
        provide: token,
        useValue: interceptor,
        multi,
    };
}

export const FmInterceptors = [

    /**
     * App initializer configs
     */
    addFactoryInterceptor(APP_INITIALIZER, startupServiceFactory, true, [DataService]),

    /**
     * Fm custom error handler
     */
    // addClassInterceptor(ErrorHandler, FmErrorHandler),

    /**
     * Http requests
     */
    addClassInterceptor(HTTP_INTERCEPTORS, HttpSessionInterceptor, true),
    addClassInterceptor(HTTP_INTERCEPTORS, SetLanguageInterceptor, true),
    addClassInterceptor(DateAdapter, MomentDateAdapter, false, [MAT_DATE_LOCALE]),
    addValueInterceptor(MAT_MOMENT_DATE_ADAPTER_OPTIONS, { useUtc: true }),


];
