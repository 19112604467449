import { Injectable } from '@angular/core';
import * as currencies from '@data/currencies.json';
import { pick } from 'lodash';

import * as locales from '@data/locales.json';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

import unit_of_measure from '@data/unit_of_measure.json';
import { AppEnv } from '@app/types';
import { Platforms } from 'fm-core';

export function startupServiceFactory(dataService: DataService): Function {
    return () => firstValueFrom(dataService.configs());
}

export let professions = [];
export let specialities = [];

@Injectable({
    providedIn: 'root',
})
export class DataService {
    env$: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>({});

    get env() {
        return this.env$.value as Record<string, any>;
    }

    professionsChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    specialitiesChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    titlesChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    langChange: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

    constructor(private readonly http: HttpClient) {}

    get locales() {
        return locales;
    }

    get availableLocales() {
        return pick(this.locales, this.langChange.value);
    }

    set availableLocales(languagues: any) {
        this.langChange.next(languagues);
    }

    get professions() {
        return this.professionsChange.value;
    }

    set professions(professions) {
        this.professionsChange.next(professions);
    }

    get specialities() {
        return this.specialitiesChange.value;
    }

    set specialities(specialities) {
        this.specialitiesChange.next(specialities);
    }

    get titles() {
        return this.titlesChange.value;
    }

    set titles(titles) {
        this.titlesChange.next(titles);
    }

    get currencies() {
        return currencies;
    }

    get unit_of_measure() {
        return unit_of_measure;
    }

    getCurrencySymbol(currency: Record<string, any>): string {
        return this.currencies.find((c: any) => c.code === currency)?.symbol ?? '';
    }

    /**
     * @link api/v1/patient-portal/configs
     * @returns
     */
    configs(): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders().set('Freddiemed-Platform', `${Platforms.DISPENSATION}`);

        return this.http.get(`${environment.API_URL}configs`, { headers }).pipe(
            map((r: any) => {
                this.availableLocales = r.languagues;

                if (r.professions) {
                    professions = this.professions = r.professions;
                }

                if (r.specialities) {
                    specialities = this.specialities = r.specialities;
                }

                if (r.titles) {
                    this.titles = Object.entries(r.titles).map((t) => {
                        return { key: +t[0], value: t[1] };
                    });
                }

                if (r.env) {
                    this._validateEnvData(r.env);
                    this.env$.next(r.env);
                }
            })
        );
    }

    /**
     * Validates env data that comes from the server.
     * In case one of these properties is missing from data,
     * and exception will be thrown and the application
     * will not be bootstrap correctly.
     *
     * @param env
     */
     private _validateEnvData(env: AppEnv): void | never {
        const keys: Array<string> = [
            'LANDING_PAGE_URL',
            'GOOGLE_ANALYTICS_TRACKING_ID',
        ];

        if (env === null) {
            throw new Error(`Missing configuration. Env cannot be null.`);
        }

        keys.forEach((key: string) => {
            if (!env.hasOwnProperty(key)) {
                throw new Error(`Missing configuration. The key "${key}" does not exists in configs request.`);
            }
        });
    }
}
