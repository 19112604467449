import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FmInterceptors } from './interceptors';

import localeEn from '@angular/common/locales/en';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import localePt from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt-PT';
import localeUk from '@angular/common/locales/uk';
import localeExtraUK from '@angular/common/locales/extra/uk';
import localeRu from '@angular/common/locales/ru';
import localeExtraRU from '@angular/common/locales/extra/ru';

import { FmApps, FmErrorHandlerModule } from 'fm-core';
import { environment as env } from '@env/environment';

registerLocaleData(localeEn, 'en-GB', localeEnGbExtra);
registerLocaleData(localePt, 'pt-PT', localeExtraPT);
registerLocaleData(localeUk, 'uk', localeExtraUK);
registerLocaleData(localeRu, 'ru', localeExtraRU);

export let AppInjector: Injector;

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,

        /**
         * Custom FM error handler
         */
        FmErrorHandlerModule.forRoot({
            production: env.PRODUCTION,
            apiUrl: env.API_URL + 'logger',
            appName: FmApps.DISPENSATION_PORTAL,
            /* versionVerUrl: env.APP_URI, */
            versionVerUrl: window.location.origin,
        }),
    ],
    providers: FmInterceptors,
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
