import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SetLanguageInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const lang = window.location.href.split('/')[3].replace('-', '_');
        const clonedRequest = request.clone({ setHeaders: { 'FreddieMed-Language': lang } });
        return next.handle(clonedRequest);
    }
}
