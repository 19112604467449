import { inject, Injectable } from '@angular/core';
import { ENV } from '@app/tokens/env.token';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    env = inject(ENV);

    init(): void {
        const node: HTMLScriptElement = document.createElement('script');
        node.src = 'https://www.google-analytics.com/analytics.js';
        node.type = 'text/javascript';
        node.async = true;
        node.setAttribute('class', 'google-analytics');
        document.getElementsByTagName('head')[0].appendChild(node);

        (window as any).ga('create', this.env.GOOGLE_ANALYTICS_TRACKING_ID, 'auto');
        (window as any).ga('send', 'pageview');
    }

    destroy(): void {
        document.querySelectorAll('.google-analytics').forEach((e: any) => e.remove());
    }
}
