import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpSessionInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => this._handleError(err)));
    }

    private _handleError(error: HttpErrorResponse): Observable<any> {
        // (() => new FmErrorHandler().handleError(error))();
        return throwError(() => error);
    }
}
