import { Component } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { CookieConsentService } from './services/cookie-consent.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
    private readonly subscriptions: Subscription = new Subscription();

    constructor(
        private cookieConsentService: CookieConsentService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private router: Router
    ) {
        (window as any).ga =
            (window as any).ga ||
            function () {
                ((window as any).ga.q = (window as any).ga.q || []).push(arguments);
            };
        (window as any).ga.l = +new Date();

        this.subscriptions.add(this.router.events.subscribe((event: any) => this.navigationInterceptor(event)));

        this.initCookieConsent();
        this._initTrimInputMode();
    }

    /**
     * Enable all input values are trimed.
     */
    private _initTrimInputMode(): void {
        const original = DefaultValueAccessor.prototype.registerOnChange;

        DefaultValueAccessor.prototype.registerOnChange = function (fn) {
            return original.call(this, (value) => {
                return fn(typeof value === 'string' ? value.trim() : value);
            });
        };
    }

    /**
     * Navigation interceptor.
     *
     * @param {RouterEvent} event
     * @return void
     */
    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationEnd) {
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
        }
    }

    /**
     * Init cookie consent popup.
     *
     * @return void
     */
    private initCookieConsent(): void {
        this.cookieConsentService.init();

        this.subscriptions.add(
            this.cookieConsentService.cookieConsentDataChange.subscribe((option: string) => {
                if (option === 'all') {
                    this.googleAnalyticsService.init();
                    this.cookieConsentService.log('Init Google analytics service');
                } else if (option === 'necessary') {
                    this.googleAnalyticsService.destroy();
                }
            })
        );
    }
}
