import { inject, Injectable } from '@angular/core';
import { ENV } from '@app/tokens/env.token';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    env = inject(ENV);

    cookieConsentDataChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private _shouldLog = true;

    constructor() {}

    /**
     * Add this in angular.json.
     * We need to set the same domain so we can communicate with the iframe.
     *
     * "options": {
     *     "browserTarget": "freddie-med-professional:build",
     *      "host": "doctors.freddiemed.test",
     *      "port": 8000
     *  }
     */
    init(): void {
        const cookies: any = document.cookie
            .split(';')
            .reduce((ac, cv, i) => Object.assign(ac, { [cv.split('=')[0].trim()]: cv.split('=')[1] }), {});

        if (!cookies.hasOwnProperty('accepted-cookies')) {
            this.log('Init cookie service');
            this._callCookieConsent();

            const self = this;

            window.onmessage = function (e) {
                if (e.data && e.data.cookies) {
                    self.log('Received data from the parent. Changed the cookie consent for: ', e.data.cookies);
                    self.cookieConsentDataChange.next(e.data.cookies);
                }
            };
        } else {
            this.log('Already has accepted-cookie: ' + cookies['accepted-cookies']);
            this.cookieConsentDataChange.next(cookies['accepted-cookies']);
        }
    }

    log(message: string, data: any = null): void {
        if (this._shouldLog) {
            console.group(`[COOKIE SERVICE] ${new Date()}`);

            if (data) {
                console.log(message, data);
            } else {
                console.log(message);
            }
            console.groupEnd();
        }
    }

    private _callCookieConsent(): void {
        const landingUrl = document.createElement('script');
        landingUrl.innerHTML = `var landingPageUrl = "${this.env.LANDING_PAGE_URL}";`;
        document.getElementsByTagName('head')[0].appendChild(landingUrl);

        const node = document.createElement('script');
        node.id = 'fm-cookies';
        node.src =
            this.env.LANDING_PAGE_URL +
            '/cookie-consent' +
            (this.env.PRODUCTION ? '/' + window.location.href.split('/')[3].replace('-', '_') : '');
        node.type = 'text/javascript';
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
