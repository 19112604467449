import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
    },
    {
        path: 'dispenser',
        loadChildren: () => import('./modules/dispenser/dispenser.module').then((m) => m.DispenserModule),
    },
    {
        path: 'terms',
        loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule),
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation:'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
